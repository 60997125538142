
import Taxonomy from '@/graphql/queries/taxonomies/Taxonomy'

export default {
  vessel: {
    section: {
      position: 'relative'
    }
  },
  async fetch() {
    const { data } = await this.$graphql.query({
      clientId: 'eshop',
      query: Taxonomy,
      variables: { where: { kind: 'category' }, children: 5 }
    })

    this.categories = data.root.children.map(taxon => ({
      label: taxon.name,
      path: { name: 'categories-id', params: { id: taxon.slug } },
      translate: false
    }))
  },
  data() {
    return {
      quickLinks: [
        {
          path: 'shop',
          label: 'pages.shop',
          translate: true
        },
        {
          path: 'promotions',
          label: 'pages.offers',
          translate: true
        },
        {
          path: 'shopping-cart',
          label: 'eshop.shoppingCart',
          translate: true
        },
        {
          path: 'cookie-policy',
          label: 'site.cookiePolicy',
          translate: true
        },
        {
          path: 'contact',
          label: 'pages.contact',
          translate: true
        }
      ],
      categories: []
    }
  }
}
