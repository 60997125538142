import { render, staticRenderFns } from "./TopBar.vue?vue&type=template&id=4bbfeba7"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchInput: require('/opt/build/repo/components/SearchInput.vue').default,Logo: require('/opt/build/repo/components/Logo.vue').default,LayoutMenuActions: require('/opt/build/repo/components/layout/MenuActions.vue').default})
