export default async (ctx, inject) => {
  const options = {"el":{"email":"info@babaaurum.com","address":"Κλειτίου 6, Αθήνα","phone":"+30 211 7109140","geoAddress":"Baba Au Rum","businessHours":[{"days":"Δευτέρα - Κυριακή","hours":"19:00 - 03:00"}]},"en":{"email":"info@babaaurum.com","address":"Klitiou 6, Athens","phone":"+30 211 7109140","geoAddress":"Baba Au Rum","businessHours":[{"days":"Monday - Sunday","hours":"19:00 - 03:00"}]}}
  const attrs = Object.values(options)[0]

  const contact = {
    ...options,
    get details() {
      return this[ctx.app.i18n.vm.locale] || {}
    }
  }

  Object.keys(attrs).forEach(key => {
    Object.defineProperty(contact, key, {
      get() {
        return this.details[key]
      }
    })
  })

  inject('contact', contact)
}
