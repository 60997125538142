
export default {
  vessel: {
    social: {
      fontSize: 'md',
      marginTop: 'lg',
      negativeMarginLeft: 'xs'
    }
  },
  computed: {
    socialAccounts() {
      return [
        {
          name: 'Facebook',
          icon: 'facebook',
          url: 'https://www.facebook.com/pages/Baba-Au-Rum/430059097078269'
        },
        {
          name: 'Instagram',
          icon: 'instagram',
          url: 'https://www.instagram.com/baba_au_rum'
        }
      ]
    }
  }
}
