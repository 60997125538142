import locale77427e78 from '.././nuxt-locales/en.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en","numberFormats":{"en-US":{"currency":{"style":"currency","currency":"EUR"}},"el-GR":{"currency":{"style":"currency","currency":"EUR"}}}},
  vueI18nLoader: false,
  locales: [{"code":"en","file":"en.js","iso":"en-US","name":"EN","label":"English"},{"code":"el","file":"el.js","iso":"el-GR","name":"EL","label":"Ελληνικά"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: ".nuxt/nuxt-locales/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "https://www.babaaurum.com",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  availableLocales: ["el","en"],
  normalizedLocales: [{"code":"en","file":"en.js","iso":"en-US","name":"EN","label":"English"},{"code":"el","file":"el.js","iso":"el-GR","name":"EL","label":"Ελληνικά"}],
  localeCodes: ["en","el"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: "/",
  12: "n",
  13: "u",
  14: "x",
  15: "t",
  16: "-",
  17: "l",
  18: "o",
  19: "c",
  20: "a",
  21: "l",
  22: "e",
  23: "s",
  24: "/",
  25: "e",
  26: "n",
  27: ".",
  28: "j",
  29: "s",
  30: "\"",
  31: ",",
  32: "\"",
  33: "e",
  34: "l",
  35: ".",
  36: "j",
  37: "s",
  38: "\"",
  39: ":",
  40: "\"",
  41: ".",
  42: "/",
  43: "n",
  44: "u",
  45: "x",
  46: "t",
  47: "-",
  48: "l",
  49: "o",
  50: "c",
  51: "a",
  52: "l",
  53: "e",
  54: "s",
  55: "/",
  56: "e",
  57: "l",
  58: ".",
  59: "j",
  60: "s",
  61: "\"",
  62: "}",
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'el.js': () => import('.././nuxt-locales/el.js' /* webpackChunkName: "lang-el.js" */),
}
