
export default {
  props: {
    headerImage: {
      type: Boolean,
      default: false
    }
  },
  head() {
    return {
      titleTemplate(title) {
        return title ? `${title} | ${this.$t('siteName')}` : this.$t('siteName')
      }
    }
  },
  vessel: {
    footer: {
      overflow: 'hidden'
    }
  },
  data() {
    return {
      stuck: false,
      linksLeft: [
        { label: 'pages.home', route: 'index' },
        { label: 'pages.menu', route: 'the-menu' }
      ],
      linksRight: [
        { label: 'pages.blog', route: 'the-blog' },
        { label: 'pages.contact', route: 'contact' }
      ]
    }
  },
  computed: {
    menuSectionAttrs() {
      return {
        items: 'center',
        width: 'full',
        grow: true
      }
    },
    isShop() {
      return this.$route.name.startsWith('shop')
    },
    buttonStyle() {
      return {
        textTransform: 'capitalize',
        outline: 'none'
      }
    }
  },
  methods: {
    onStick() {
      this.stuck = true
    },
    onUnstick() {
      this.stuck = false
    }
  }
}
