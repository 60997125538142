import { render, staticRenderFns } from "./SocialAccounts.vue?vue&type=template&id=0d5aab32"
import script from "./SocialAccounts.vue?vue&type=script&lang=js"
export * from "./SocialAccounts.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ScSocialIcons: require('/opt/build/repo/node_modules/@evince/site/lib/packages/SocialIcons.vue').default})
