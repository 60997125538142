import { make } from 'vuex-pathify'

export const state = () => ({
  search: '',
  showFunding: true
})

export const actions   = make.actions(state)
export const mutations = make.mutations(state)
export const getters   = make.getters(state)
