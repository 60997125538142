import pathify from 'vuex-pathify'
import createPersistedState from 'vuex-persistedstate'

import auth from './vuex-modules/auth.js'

import user from './vuex-modules/user.js'

import productsSearch from './vuex-modules/products-search.js'

import shoppingCart from './vuex-modules/shopping-cart.js'

import sharedCart from './vuex-modules/shared-cart.js'

import site from './vuex-modules/site.js'

export default function({ store }) {
  pathify.options.deep = 2
  pathify.plugin(store)

  const options = {
    preserveState: false
  }

  if (typeof store == undefined) {
    console.warn('Vuex store not detected')
  }

  store.registerModule('auth', auth, options)

  store.registerModule('user', user, options)

  store.registerModule('productsSearch', productsSearch, options)

  store.registerModule('shoppingCart', shoppingCart, options)

  store.registerModule('sharedCart', sharedCart, options)

  store.registerModule('site', site, options)

  if (process.client) {
    const sessionPaths = ["productsSearch"]
    const storagePaths = Object.keys(store.state || {}).filter(mod => !sessionPaths.includes(mod))

    if (sessionPaths.length) {
      const session = createPersistedState({
        storage: window.sessionStorage,
        paths: sessionPaths
      })

      session(store)
    }

    if (storagePaths.length) {
      const storage = createPersistedState({
        storage: window.localStorage,
        paths: storagePaths
      })

      storage(store)
    }
  }
}
