
export default {
  methods: {
    onChange(value) {
      if (value) {
        const route = {
          name: 'products',
          query: {
            search: value
          }
        }

        this.$navigateTo(route)
      }
    }
  }
}
