export const AuthSession = () => import('../../node_modules/@evince/auth/lib/packages/AuthSession.vue' /* webpackChunkName: "components/auth-session" */).then(c => wrapFunctional(c.default || c))
export const AuthSessionForm = () => import('../../node_modules/@evince/auth/lib/packages/AuthSessionForm.vue' /* webpackChunkName: "components/auth-session-form" */).then(c => wrapFunctional(c.default || c))
export const EshopCart = () => import('../../node_modules/@evince/eshop/lib/packages/Cart.vue' /* webpackChunkName: "components/eshop-cart" */).then(c => wrapFunctional(c.default || c))
export const EshopCartAdd = () => import('../../node_modules/@evince/eshop/lib/packages/CartAdd.vue' /* webpackChunkName: "components/eshop-cart-add" */).then(c => wrapFunctional(c.default || c))
export const EshopCartAddButton = () => import('../../node_modules/@evince/eshop/lib/packages/CartAddButton.vue' /* webpackChunkName: "components/eshop-cart-add-button" */).then(c => wrapFunctional(c.default || c))
export const EshopCartAddForm = () => import('../../node_modules/@evince/eshop/lib/packages/CartAddForm.vue' /* webpackChunkName: "components/eshop-cart-add-form" */).then(c => wrapFunctional(c.default || c))
export const EshopCartDrawer = () => import('../../node_modules/@evince/eshop/lib/packages/CartDrawer.vue' /* webpackChunkName: "components/eshop-cart-drawer" */).then(c => wrapFunctional(c.default || c))
export const EshopCartForm = () => import('../../node_modules/@evince/eshop/lib/packages/CartForm.vue' /* webpackChunkName: "components/eshop-cart-form" */).then(c => wrapFunctional(c.default || c))
export const EshopCartRemove = () => import('../../node_modules/@evince/eshop/lib/packages/CartRemove.vue' /* webpackChunkName: "components/eshop-cart-remove" */).then(c => wrapFunctional(c.default || c))
export const EshopCartToggle = () => import('../../node_modules/@evince/eshop/lib/packages/CartToggle.vue' /* webpackChunkName: "components/eshop-cart-toggle" */).then(c => wrapFunctional(c.default || c))
export const EshopSearchInput = () => import('../../node_modules/@evince/eshop/lib/packages/SearchInput.vue' /* webpackChunkName: "components/eshop-search-input" */).then(c => wrapFunctional(c.default || c))
export const PageEshopProducts = () => import('../../node_modules/@evince/eshop/lib/templates/Products.vue' /* webpackChunkName: "components/page-eshop-products" */).then(c => wrapFunctional(c.default || c))
export const ScAddressLink = () => import('../../node_modules/@evince/site/lib/packages/AddressLink.vue' /* webpackChunkName: "components/sc-address-link" */).then(c => wrapFunctional(c.default || c))
export const ScBrowserList = () => import('../../node_modules/@evince/site/lib/packages/BrowserList.vue' /* webpackChunkName: "components/sc-browser-list" */).then(c => wrapFunctional(c.default || c))
export const ScBusinessHours = () => import('../../node_modules/@evince/site/lib/packages/BusinessHours.vue' /* webpackChunkName: "components/sc-business-hours" */).then(c => wrapFunctional(c.default || c))
export const ScCookieBar = () => import('../../node_modules/@evince/site/lib/packages/CookieBar.vue' /* webpackChunkName: "components/sc-cookie-bar" */).then(c => wrapFunctional(c.default || c))
export const ScDirectionsLink = () => import('../../node_modules/@evince/site/lib/packages/DirectionsLink.vue' /* webpackChunkName: "components/sc-directions-link" */).then(c => wrapFunctional(c.default || c))
export const ScDynamicForm = () => import('../../node_modules/@evince/site/lib/packages/DynamicForm.vue' /* webpackChunkName: "components/sc-dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const ScEmailLink = () => import('../../node_modules/@evince/site/lib/packages/EmailLink.vue' /* webpackChunkName: "components/sc-email-link" */).then(c => wrapFunctional(c.default || c))
export const ScFaxLink = () => import('../../node_modules/@evince/site/lib/packages/FaxLink.vue' /* webpackChunkName: "components/sc-fax-link" */).then(c => wrapFunctional(c.default || c))
export const ScLangSwitcher = () => import('../../node_modules/@evince/site/lib/packages/LangSwitcher.vue' /* webpackChunkName: "components/sc-lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const ScMainNav = () => import('../../node_modules/@evince/site/lib/packages/MainNav.vue' /* webpackChunkName: "components/sc-main-nav" */).then(c => wrapFunctional(c.default || c))
export const ScMobileLink = () => import('../../node_modules/@evince/site/lib/packages/MobileLink.vue' /* webpackChunkName: "components/sc-mobile-link" */).then(c => wrapFunctional(c.default || c))
export const ScMobileMenu = () => import('../../node_modules/@evince/site/lib/packages/MobileMenu.vue' /* webpackChunkName: "components/sc-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const ScNewsletterForm = () => import('../../node_modules/@evince/site/lib/packages/NewsletterForm.vue' /* webpackChunkName: "components/sc-newsletter-form" */).then(c => wrapFunctional(c.default || c))
export const ScPhoneLink = () => import('../../node_modules/@evince/site/lib/packages/PhoneLink.vue' /* webpackChunkName: "components/sc-phone-link" */).then(c => wrapFunctional(c.default || c))
export const ScSocialIcons = () => import('../../node_modules/@evince/site/lib/packages/SocialIcons.vue' /* webpackChunkName: "components/sc-social-icons" */).then(c => wrapFunctional(c.default || c))
export const ScViberLink = () => import('../../node_modules/@evince/site/lib/packages/ViberLink.vue' /* webpackChunkName: "components/sc-viber-link" */).then(c => wrapFunctional(c.default || c))
export const ScWhatsappLink = () => import('../../node_modules/@evince/site/lib/packages/WhatsappLink.vue' /* webpackChunkName: "components/sc-whatsapp-link" */).then(c => wrapFunctional(c.default || c))
export const ScContactButton = () => import('../../node_modules/@evince/site/lib/packages/contact/Button.vue' /* webpackChunkName: "components/sc-contact-button" */).then(c => wrapFunctional(c.default || c))
export const ScContactDetails = () => import('../../node_modules/@evince/site/lib/packages/contact/Details.vue' /* webpackChunkName: "components/sc-contact-details" */).then(c => wrapFunctional(c.default || c))
export const ScContactForm = () => import('../../node_modules/@evince/site/lib/packages/contact/Form.vue' /* webpackChunkName: "components/sc-contact-form" */).then(c => wrapFunctional(c.default || c))
export const PageContact = () => import('../../node_modules/@evince/site/lib/templates/contact.vue' /* webpackChunkName: "components/page-contact" */).then(c => wrapFunctional(c.default || c))
export const PageRequest = () => import('../../node_modules/@evince/site/lib/templates/request.vue' /* webpackChunkName: "components/page-request" */).then(c => wrapFunctional(c.default || c))
export const ScMutationForm = () => import('../../node_modules/@evince/core/lib/components/MutationForm.vue' /* webpackChunkName: "components/sc-mutation-form" */).then(c => wrapFunctional(c.default || c))
export const ScLanguageSelect = () => import('../../node_modules/@evince/core/lib/components/language/Select.vue' /* webpackChunkName: "components/sc-language-select" */).then(c => wrapFunctional(c.default || c))
export const ScCountrySelect = () => import('../../node_modules/@evince/core/lib/components/country/Select.vue' /* webpackChunkName: "components/sc-country-select" */).then(c => wrapFunctional(c.default || c))
export const ErrorDetails = () => import('../../node_modules/@evince/core/lib/packages/ErrorDetails.vue' /* webpackChunkName: "components/error-details" */).then(c => wrapFunctional(c.default || c))
export const PwaInstall = () => import('../../node_modules/@evince/core/lib/packages/PwaInstall.vue' /* webpackChunkName: "components/pwa-install" */).then(c => wrapFunctional(c.default || c))
export const PwaPrompt = () => import('../../node_modules/@evince/core/lib/packages/PwaPrompt.vue' /* webpackChunkName: "components/pwa-prompt" */).then(c => wrapFunctional(c.default || c))
export const StateLabel = () => import('../../node_modules/@evince/core/lib/packages/StateLabel.vue' /* webpackChunkName: "components/state-label" */).then(c => wrapFunctional(c.default || c))
export const GraphqlMutation = () => import('../../node_modules/@evince/core/lib/packages/graphql/Mutation.vue' /* webpackChunkName: "components/graphql-mutation" */).then(c => wrapFunctional(c.default || c))
export const GraphqlQuery = () => import('../../node_modules/@evince/core/lib/packages/graphql/Query.vue' /* webpackChunkName: "components/graphql-query" */).then(c => wrapFunctional(c.default || c))
export const FundingNotice = () => import('../../components/FundingNotice.vue' /* webpackChunkName: "components/funding-notice" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const NewsletterSubscribe = () => import('../../components/NewsletterSubscribe.vue' /* webpackChunkName: "components/newsletter-subscribe" */).then(c => wrapFunctional(c.default || c))
export const SearchInput = () => import('../../components/SearchInput.vue' /* webpackChunkName: "components/search-input" */).then(c => wrapFunctional(c.default || c))
export const ShopBanner = () => import('../../components/ShopBanner.vue' /* webpackChunkName: "components/shop-banner" */).then(c => wrapFunctional(c.default || c))
export const SocialAccounts = () => import('../../components/SocialAccounts.vue' /* webpackChunkName: "components/social-accounts" */).then(c => wrapFunctional(c.default || c))
export const UserSession = () => import('../../components/UserSession.vue' /* webpackChunkName: "components/user-session" */).then(c => wrapFunctional(c.default || c))
export const BlogAside = () => import('../../components/blog/Aside.vue' /* webpackChunkName: "components/blog-aside" */).then(c => wrapFunctional(c.default || c))
export const BlogLayout = () => import('../../components/blog/Layout.vue' /* webpackChunkName: "components/blog-layout" */).then(c => wrapFunctional(c.default || c))
export const CategoryCard = () => import('../../components/category/Card.vue' /* webpackChunkName: "components/category-card" */).then(c => wrapFunctional(c.default || c))
export const CategoryCarousel = () => import('../../components/category/Carousel.vue' /* webpackChunkName: "components/category-carousel" */).then(c => wrapFunctional(c.default || c))
export const CategoryList = () => import('../../components/category/List.vue' /* webpackChunkName: "components/category-list" */).then(c => wrapFunctional(c.default || c))
export const LayoutBanner = () => import('../../components/layout/Banner.vue' /* webpackChunkName: "components/layout-banner" */).then(c => wrapFunctional(c.default || c))
export const LayoutBase = () => import('../../components/layout/Base.vue' /* webpackChunkName: "components/layout-base" */).then(c => wrapFunctional(c.default || c))
export const LayoutCopyright = () => import('../../components/layout/Copyright.vue' /* webpackChunkName: "components/layout-copyright" */).then(c => wrapFunctional(c.default || c))
export const LayoutMenuActions = () => import('../../components/layout/MenuActions.vue' /* webpackChunkName: "components/layout-menu-actions" */).then(c => wrapFunctional(c.default || c))
export const LayoutPageHeader = () => import('../../components/layout/PageHeader.vue' /* webpackChunkName: "components/layout-page-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutShopHeader = () => import('../../components/layout/ShopHeader.vue' /* webpackChunkName: "components/layout-shop-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutTopBar = () => import('../../components/layout/TopBar.vue' /* webpackChunkName: "components/layout-top-bar" */).then(c => wrapFunctional(c.default || c))
export const MenuItems = () => import('../../components/menu/Items.vue' /* webpackChunkName: "components/menu-items" */).then(c => wrapFunctional(c.default || c))
export const MenuTabs = () => import('../../components/menu/Tabs.vue' /* webpackChunkName: "components/menu-tabs" */).then(c => wrapFunctional(c.default || c))
export const PostCardHorizontal = () => import('../../components/post/CardHorizontal.vue' /* webpackChunkName: "components/post-card-horizontal" */).then(c => wrapFunctional(c.default || c))
export const PostCardMinimal = () => import('../../components/post/CardMinimal.vue' /* webpackChunkName: "components/post-card-minimal" */).then(c => wrapFunctional(c.default || c))
export const PostCardVertical = () => import('../../components/post/CardVertical.vue' /* webpackChunkName: "components/post-card-vertical" */).then(c => wrapFunctional(c.default || c))
export const PostList = () => import('../../components/post/List.vue' /* webpackChunkName: "components/post-list" */).then(c => wrapFunctional(c.default || c))
export const PostMeta = () => import('../../components/post/Meta.vue' /* webpackChunkName: "components/post-meta" */).then(c => wrapFunctional(c.default || c))
export const PromotionExtendedCard = () => import('../../components/promotion/ExtendedCard.vue' /* webpackChunkName: "components/promotion-extended-card" */).then(c => wrapFunctional(c.default || c))
export const PromotionList = () => import('../../components/promotion/List.vue' /* webpackChunkName: "components/promotion-list" */).then(c => wrapFunctional(c.default || c))
export const PromotionProductCard = () => import('../../components/promotion/ProductCard.vue' /* webpackChunkName: "components/promotion-product-card" */).then(c => wrapFunctional(c.default || c))
export const PromotionProducts = () => import('../../components/promotion/Products.vue' /* webpackChunkName: "components/promotion-products" */).then(c => wrapFunctional(c.default || c))
export const ProductAttributes = () => import('../../components/product/Attributes.vue' /* webpackChunkName: "components/product-attributes" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/product/Card.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCarousel = () => import('../../components/product/Carousel.vue' /* webpackChunkName: "components/product-carousel" */).then(c => wrapFunctional(c.default || c))
export const ProductCarouselCard = () => import('../../components/product/CarouselCard.vue' /* webpackChunkName: "components/product-carousel-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCountry = () => import('../../components/product/Country.vue' /* webpackChunkName: "components/product-country" */).then(c => wrapFunctional(c.default || c))
export const ProductDetails = () => import('../../components/product/Details.vue' /* webpackChunkName: "components/product-details" */).then(c => wrapFunctional(c.default || c))
export const ProductGallery = () => import('../../components/product/Gallery.vue' /* webpackChunkName: "components/product-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductGalleryCard = () => import('../../components/product/GalleryCard.vue' /* webpackChunkName: "components/product-gallery-card" */).then(c => wrapFunctional(c.default || c))
export const ProductGalleryCarousel = () => import('../../components/product/GalleryCarousel.vue' /* webpackChunkName: "components/product-gallery-carousel" */).then(c => wrapFunctional(c.default || c))
export const ProductMeasurements = () => import('../../components/product/Measurements.vue' /* webpackChunkName: "components/product-measurements" */).then(c => wrapFunctional(c.default || c))
export const ProductOfferCard = () => import('../../components/product/OfferCard.vue' /* webpackChunkName: "components/product-offer-card" */).then(c => wrapFunctional(c.default || c))
export const ProductPrice = () => import('../../components/product/Price.vue' /* webpackChunkName: "components/product-price" */).then(c => wrapFunctional(c.default || c))
export const ProductSearch = () => import('../../components/product/Search.vue' /* webpackChunkName: "components/product-search" */).then(c => wrapFunctional(c.default || c))
export const ProductTaxonLink = () => import('../../components/product/TaxonLink.vue' /* webpackChunkName: "components/product-taxon-link" */).then(c => wrapFunctional(c.default || c))
export const ProductVariants = () => import('../../components/product/Variants.vue' /* webpackChunkName: "components/product-variants" */).then(c => wrapFunctional(c.default || c))
export const SectionAwards = () => import('../../components/section/Awards.vue' /* webpackChunkName: "components/section-awards" */).then(c => wrapFunctional(c.default || c))
export const SectionBlog = () => import('../../components/section/Blog.vue' /* webpackChunkName: "components/section-blog" */).then(c => wrapFunctional(c.default || c))
export const SectionFeaturedProducts = () => import('../../components/section/FeaturedProducts.vue' /* webpackChunkName: "components/section-featured-products" */).then(c => wrapFunctional(c.default || c))
export const SectionMenu = () => import('../../components/section/Menu.vue' /* webpackChunkName: "components/section-menu" */).then(c => wrapFunctional(c.default || c))
export const SectionNewArrivals = () => import('../../components/section/NewArrivals.vue' /* webpackChunkName: "components/section-new-arrivals" */).then(c => wrapFunctional(c.default || c))
export const SectionOffers = () => import('../../components/section/Offers.vue' /* webpackChunkName: "components/section-offers" */).then(c => wrapFunctional(c.default || c))
export const SectionPromotion = () => import('../../components/section/Promotion.vue' /* webpackChunkName: "components/section-promotion" */).then(c => wrapFunctional(c.default || c))
export const SectionRelatedProducts = () => import('../../components/section/RelatedProducts.vue' /* webpackChunkName: "components/section-related-products" */).then(c => wrapFunctional(c.default || c))
export const SectionShop = () => import('../../components/section/Shop.vue' /* webpackChunkName: "components/section-shop" */).then(c => wrapFunctional(c.default || c))
export const SectionVideo = () => import('../../components/section/Video.vue' /* webpackChunkName: "components/section-video" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooterColumn = () => import('../../components/layout/footer/Column.vue' /* webpackChunkName: "components/layout-footer-column" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooterColumns = () => import('../../components/layout/footer/Columns.vue' /* webpackChunkName: "components/layout-footer-columns" */).then(c => wrapFunctional(c.default || c))
export const MenuCategoryItems = () => import('../../components/menu/category/Items.vue' /* webpackChunkName: "components/menu-category-items" */).then(c => wrapFunctional(c.default || c))
export const MenuCategoryList = () => import('../../components/menu/category/List.vue' /* webpackChunkName: "components/menu-category-list" */).then(c => wrapFunctional(c.default || c))
export const MenuCategoryListPlain = () => import('../../components/menu/category/ListPlain.vue' /* webpackChunkName: "components/menu-category-list-plain" */).then(c => wrapFunctional(c.default || c))
export const MenuCategoryOther = () => import('../../components/menu/category/Other.vue' /* webpackChunkName: "components/menu-category-other" */).then(c => wrapFunctional(c.default || c))
export const ProductPageDesktop = () => import('../../components/product/page/Desktop.vue' /* webpackChunkName: "components/product-page-desktop" */).then(c => wrapFunctional(c.default || c))
export const ProductPageMobile = () => import('../../components/product/page/Mobile.vue' /* webpackChunkName: "components/product-page-mobile" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
