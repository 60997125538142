import 'cross-fetch/polyfill'

import Vue from 'vue'
import VueApollo from 'vue-apollo'

import GraphqlQuery from '/opt/build/repo/node_modules/@evince/core/lib/packages/graphql/graphql-query'
import GraphqlMutation from '/opt/build/repo/node_modules/@evince/core/lib/packages/graphql/graphql-mutation'

import sha256 from 'hash.js/lib/hash/sha/256'

import { createHttpLink } from '@apollo/client/core'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'

import { ApolloLink } from '@apollo/client/core'
import { ApolloClient } from '@apollo/client/core'
import { InMemoryCache } from '@apollo/client/core'

export default ({ app, beforeNuxtRender, isDev }, inject) => {
  const config = {"subscriptions":false,"httpCache":true}
  let timeZone = null

  try {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch (e) {
    timeZone = 'UTC'
  }

  Vue.use(VueApollo)

  const dataIdFromObject = (object) => {
    if (object.gid) {
      return object.gid
    } else if (object.id || object._id) {
      return `${object.__typename}:${object.id || object._id}`
    } else {
      return null
    }
  }

  const mergeHeaders = (headers) => {
    const object = {
      Tenant: app.$tenant.active,
      Locale: app.i18n.locale,
      Timezone: timeZone,
      ...headers
    }

    if (app.$auth && app.$auth.loggedIn) {
      object['Authorization'] = app.$auth.getToken()
    }

    return object
  }

  const createMiddlewareLink = ({ headers, cable, persist, ...options }) => {
    const httpLink = createHttpLink(options)
    const middlewareLink = new ApolloLink((operation, forward) => {
      const context = operation.getContext()
      const appHeaders = mergeHeaders({ ...headers, ...context.headers })

      operation.setContext({
        ...context,
        headers: appHeaders
      })

      return forward(operation)
    })

    let link = middlewareLink.concat(httpLink)

    if (persist == true) {
      const useGETForHashedQueries = config.httpCache !== false

      link = createPersistedQueryLink({
        useGETForHashedQueries,
        sha256(data) {
          return sha256().update(data).digest('hex')
        }
      }).concat(link)
    }

    return link
  }

  const createCache = (options) => {
    return new InMemoryCache({
      dataIdFromObject,
      ...options
    })
  }

  const createClient = (options) => {
    return new ApolloClient({ dataIdFromObject, ...options, connectToDevTools: isDev })
  }

  const oauthClient = (endpoint) => {
    const cache = createCache()
    const link = createMiddlewareLink({
      uri: endpoint,
      headers: {
        Tenant: 'baba-au-rum',
        Timezone: timeZone
      }
    })

    return createClient({ link, cache })
  }

  const apolloClient = (endpoint) => {
    const cache = createCache()
    const link = createMiddlewareLink({
      uri: endpoint,
      cable: true,
      persist: true
    })

    return createClient({ link, cache })
  }

  const clients = {
    default: apolloClient(process.env.MAIN_GRAPHQL),
    oauth: oauthClient(process.env.AUTH_GRAPHQL),
    auth: apolloClient(process.env.DATA_GRAPHQL),
    site: apolloClient(process.env.DATA_GRAPHQL),
    eshop: apolloClient(process.env.DATA_GRAPHQL),
    hotel: apolloClient(process.env.DATA_GRAPHQL)
  }

  const apolloProvider = new VueApollo({
    defaultClient: clients.default,
    clients: clients,
    errorHandler(error) {
      console.error('GraphQL Error:', error.message)
    }
  })

  app.apolloProvider = apolloProvider

  const eachClient = async (callback) => {
    for (const client in clients) {
      if (clients.hasOwnProperty(client)) {
        try {
          await callback(clients[client])
        } catch (e) {
          console.error(`GraphQL Error [${client}]:`, e.message)
        }
      }
    }
  }

  if (process.server) {
    const ApolloSSR = require('vue-apollo/ssr')

    beforeNuxtRender(async ({ nuxtState }) => {
      nuxtState.apollo = ApolloSSR.getStates(apolloProvider)
    })
  }

  inject('graphql', {
    async query(options) {
      const tenant = app.$tenant.active
      const query  = new GraphqlQuery({ tenant, ...options })

      return query.execute({ clients })
    },
    async mutate(options) {
      const tenant   = app.$tenant.active
      const mutation = new GraphqlMutation({ tenant, ...options })

      return mutation.execute({ clients })
    },

    async clearStore() {
      await eachClient(async client => await client.clearStore())
    },
    async resetStore() {
      await eachClient(async client => await client.resetStore())
    },
    async refetchQueries() {
      await eachClient(async client => await client.reFetchObservableQueries())
    }
  })
}
