
export default {
  vessel: {
    copyright: {
      fontSize: 'sm',
      paddingLeft: { lg: 'md' },
      marginBottom: { all: 'sm', lg: 0 },
      marginTop: { all: 'xs', lg: 0 },
    }
  },
  computed: {
    copyright() {
      const title = this.$t('footer.copyrightTitle')
      const rights = this.$t('footer.rightsReserved')

      return `${title}. ${rights}`
    }
  }
}
