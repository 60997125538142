
export default {
  props: {
    color: {
      type: String,
      default: 'dark'
    },
    expand: {
      type: Boolean,
      default: false
    }
  },
  vessel: {
    link: {
      $attrs: 'all',
      height: { xl: 'md' }
    }
  },
  computed: {
    dark() {
      return this.color == 'dark'
    },
    logoImage() {
      return this.dark ? '/logo.svg' : '/logo-white.svg'
    }
  }
}
