import { render, staticRenderFns } from "./Base.vue?vue&type=template&id=3f233597"
import script from "./Base.vue?vue&type=script&lang=js"
export * from "./Base.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutTopBar: require('/opt/build/repo/components/layout/TopBar.vue').default,SearchInput: require('/opt/build/repo/components/SearchInput.vue').default,ScMainNav: require('/opt/build/repo/node_modules/@evince/site/lib/packages/MainNav.vue').default,LayoutMenuActions: require('/opt/build/repo/components/layout/MenuActions.vue').default,FundingNotice: require('/opt/build/repo/components/FundingNotice.vue').default,LayoutFooterColumns: require('/opt/build/repo/components/layout/footer/Columns.vue').default,LayoutCopyright: require('/opt/build/repo/components/layout/Copyright.vue').default,EshopCartDrawer: require('/opt/build/repo/node_modules/@evince/eshop/lib/packages/CartDrawer.vue').default,ScCookieBar: require('/opt/build/repo/node_modules/@evince/site/lib/packages/CookieBar.vue').default})
