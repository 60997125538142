
import { sync } from 'vuex-pathify'

export default {
  vessel: {
    wrapper: {
      position: 'fixed',
      pinBottom: true,
      pinLeft: true,
      margin: 'md',
      padding: { all: 'xs', md: 'xs' },
      borderWidth: 1,
      shadow: true,
      zIndex: 50,
      backgroundColor: 'white',
      width: { all: '4xl', md: '5xl' },
      maxWidth: 'full'
    },
    title: {
      marginBottom: 'xs'
    },
    text: {
      fontWeight: 'light',
      fontSize: 'sm'
    },
    close: {
      position: 'absolute',
      pinTop: true,
      pinRight: true,
      cursor: 'pointer',
      zIndex: 10,
      padding: 'xs',
      backgroundColor: 'grey-lightest',
      shadow: true,
      color: 'danger',
      negativeMarginTop: 'sm',
      negativeMarginRight: 'sm',
      borderRadius: 'full',
    }
  },
  data() {
    return {
      showClose: false
    }
  },
  computed: {
    visible: sync('showFunding'),
    href() {
      return `https://www.babaaurum.com/espa-funding-${this.$i18n.locale}.pdf`
    },
    imagePath() {
      return `/espa-${this.$i18n.locale}.jpg`
    },
    showButton() {
      return this.$screen.touch || this.showClose
    },
    position() {
      let bottom = 0

      if (this.$tracker.showControls) {
        bottom = this.$screen.match({ all: '130px', lg: '70px' })
      }

      return bottom
    }
  }
}
