
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    links: {
      type: Array,
      default: () => []
    }
  },
  vessel: {
    container: {
      $attrs: 'all'
    },
    title: {
      fontSize: 'md',
      marginBottom: 'md'
    },
    item: {
      marginBottom: 'sm',
      fontSize: 'sm'
    }
  },
  methods: {
    itemLabel(item) {
      if (item.translate)
        return this.$t(item.label)
      else
        return item.label
    }
  }
}
