import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0ae7bf9c = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/checkout.vue' /* webpackChunkName: "pages/eshop/checkout" */))
const _08440926 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _69c9604b = () => interopDefault(import('../node_modules/@evince/site/lib/pages/cookie-policy.vue' /* webpackChunkName: "pages/site/cookie-policy" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _330b853b = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/orders.vue' /* webpackChunkName: "pages/eshop/orders" */))
const _516c7b85 = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/payment-methods.vue' /* webpackChunkName: "pages/eshop/payment-methods" */))
const _b427da4e = () => interopDefault(import('../pages/posts.vue' /* webpackChunkName: "pages/posts" */))
const _6c454c04 = () => interopDefault(import('../pages/posts/_id.vue' /* webpackChunkName: "pages/posts/_id" */))
const _fc71dd5e = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _bcfac6c6 = () => interopDefault(import('../pages/promotions/index.vue' /* webpackChunkName: "pages/promotions/index" */))
const _407be469 = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/shipping-methods.vue' /* webpackChunkName: "pages/eshop/shipping-methods" */))
const _dc08b240 = () => interopDefault(import('../pages/shop.vue' /* webpackChunkName: "pages/shop" */))
const _5bd9787f = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/shopping-cart.vue' /* webpackChunkName: "pages/eshop/shopping-cart" */))
const _0229a59b = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/terms-of-service.vue' /* webpackChunkName: "pages/eshop/terms-of-service" */))
const _0ca80330 = () => interopDefault(import('../pages/the-blog.vue' /* webpackChunkName: "pages/the-blog" */))
const _05c484b6 = () => interopDefault(import('../pages/the-menu.vue' /* webpackChunkName: "pages/the-menu" */))
const _194cb468 = () => interopDefault(import('../pages/the-menu/index.vue' /* webpackChunkName: "pages/the-menu/index" */))
const _29ccb0d0 = () => interopDefault(import('../pages/the-menu/_id.vue' /* webpackChunkName: "pages/the-menu/_id" */))
const _d6fc104c = () => interopDefault(import('../node_modules/@evince/auth/lib/pages/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _824a4a7c = () => interopDefault(import('../node_modules/@evince/auth/lib/pages/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _c111b79e = () => interopDefault(import('../pages/categories/_id.vue' /* webpackChunkName: "pages/categories/_id" */))
const _44eda9c6 = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/orders-id.vue' /* webpackChunkName: "pages/eshop/orders-id" */))
const _4849980e = () => interopDefault(import('../pages/products/_id.vue' /* webpackChunkName: "pages/products/_id" */))
const _394dbb76 = () => interopDefault(import('../pages/promotions/_id.vue' /* webpackChunkName: "pages/promotions/_id" */))
const _13972652 = () => interopDefault(import('../node_modules/@evince/eshop/lib/pages/receipt.vue' /* webpackChunkName: "pages/eshop/receipt" */))
const _7d1593c2 = () => interopDefault(import('../node_modules/@evince/site/lib/pages/request.vue' /* webpackChunkName: "pages/site/request" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _0ae7bf9c,
    name: "checkout___en"
  }, {
    path: "/contact",
    component: _08440926,
    name: "contact___en"
  }, {
    path: "/cookie-policy",
    component: _69c9604b,
    name: "cookie-policy___en"
  }, {
    path: "/el",
    component: _0547af50,
    name: "index___el"
  }, {
    path: "/orders",
    component: _330b853b,
    name: "orders___en"
  }, {
    path: "/payment-methods",
    component: _516c7b85,
    name: "payment-methods___en"
  }, {
    path: "/posts",
    component: _b427da4e,
    name: "posts___en",
    children: [{
      path: ":id?",
      component: _6c454c04,
      name: "posts-id___en"
    }]
  }, {
    path: "/products",
    component: _fc71dd5e,
    name: "products___en"
  }, {
    path: "/promotions",
    component: _bcfac6c6,
    name: "promotions___en"
  }, {
    path: "/shipping-methods",
    component: _407be469,
    name: "shipping-methods___en"
  }, {
    path: "/shop",
    component: _dc08b240,
    name: "shop___en"
  }, {
    path: "/shopping-cart",
    component: _5bd9787f,
    name: "shopping-cart___en"
  }, {
    path: "/terms-of-service",
    component: _0229a59b,
    name: "terms-of-service___en"
  }, {
    path: "/the-blog",
    component: _0ca80330,
    name: "the-blog___en"
  }, {
    path: "/the-menu",
    component: _05c484b6,
    children: [{
      path: "",
      component: _194cb468,
      name: "the-menu___en"
    }, {
      path: ":id",
      component: _29ccb0d0,
      name: "the-menu-id___en"
    }]
  }, {
    path: "/auth/login",
    component: _d6fc104c,
    name: "auth-login___en"
  }, {
    path: "/auth/register",
    component: _824a4a7c,
    name: "auth-register___en"
  }, {
    path: "/el/checkout",
    component: _0ae7bf9c,
    name: "checkout___el"
  }, {
    path: "/el/contact",
    component: _08440926,
    name: "contact___el"
  }, {
    path: "/el/cookie-policy",
    component: _69c9604b,
    name: "cookie-policy___el"
  }, {
    path: "/el/orders",
    component: _330b853b,
    name: "orders___el"
  }, {
    path: "/el/payment-methods",
    component: _516c7b85,
    name: "payment-methods___el"
  }, {
    path: "/el/posts",
    component: _b427da4e,
    name: "posts___el",
    children: [{
      path: ":id?",
      component: _6c454c04,
      name: "posts-id___el"
    }]
  }, {
    path: "/el/products",
    component: _fc71dd5e,
    name: "products___el"
  }, {
    path: "/el/promotions",
    component: _bcfac6c6,
    name: "promotions___el"
  }, {
    path: "/el/shipping-methods",
    component: _407be469,
    name: "shipping-methods___el"
  }, {
    path: "/el/shop",
    component: _dc08b240,
    name: "shop___el"
  }, {
    path: "/el/shopping-cart",
    component: _5bd9787f,
    name: "shopping-cart___el"
  }, {
    path: "/el/terms-of-service",
    component: _0229a59b,
    name: "terms-of-service___el"
  }, {
    path: "/el/the-blog",
    component: _0ca80330,
    name: "the-blog___el"
  }, {
    path: "/el/the-menu",
    component: _05c484b6,
    children: [{
      path: "",
      component: _194cb468,
      name: "the-menu___el"
    }, {
      path: ":id",
      component: _29ccb0d0,
      name: "the-menu-id___el"
    }]
  }, {
    path: "/el/auth/login",
    component: _d6fc104c,
    name: "auth-login___el"
  }, {
    path: "/el/auth/register",
    component: _824a4a7c,
    name: "auth-register___el"
  }, {
    path: "/",
    component: _0547af50,
    name: "index___en"
  }, {
    path: "/el/categories/:id?",
    component: _c111b79e,
    name: "categories-id___el"
  }, {
    path: "/el/orders/:id",
    component: _44eda9c6,
    name: "orders-id___el"
  }, {
    path: "/el/products/:id",
    component: _4849980e,
    name: "products-id___el"
  }, {
    path: "/el/promotions/:id",
    component: _394dbb76,
    name: "promotions-id___el"
  }, {
    path: "/el/receipt/:id",
    component: _13972652,
    name: "receipt___el"
  }, {
    path: "/el/request/:id?",
    component: _7d1593c2,
    name: "request-id___el"
  }, {
    path: "/categories/:id?",
    component: _c111b79e,
    name: "categories-id___en"
  }, {
    path: "/orders/:id",
    component: _44eda9c6,
    name: "orders-id___en"
  }, {
    path: "/products/:id",
    component: _4849980e,
    name: "products-id___en"
  }, {
    path: "/promotions/:id",
    component: _394dbb76,
    name: "promotions-id___en"
  }, {
    path: "/receipt/:id",
    component: _13972652,
    name: "receipt___en"
  }, {
    path: "/request/:id?",
    component: _7d1593c2,
    name: "request-id___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
