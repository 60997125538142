
export default {
  computed: {
    links() {
      const links = [
        { label: 'pages.home', route: 'index' },
        { label: 'pages.menu', route: 'the-menu' },
        { label: 'pages.shop', route: 'shop' },
        { label: 'pages.blog', route: 'the-blog' },
        { label: 'pages.contact', route: 'contact' }
      ]

      if (this.$auth.loggedIn) {
        links.push({ label: 'eshop.orders.myOrders', route: '/orders' })
      }

      return links
    }
  },
  methods: {
    toggleMenu() {
      this.$drawer.toggle('main')
    }
  }
}
